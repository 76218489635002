$(function () {
    $('.main-slider').slick({
        arrows: false,
        dots: true,
        fade: false,
        slide: '.slide',
        autoplay: false,
        infinite: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    $('.video-slider').slick({
        arrows: false,
        dots: true,
        fade: false,
        slide: '.video-box',
        autoplay: false,
        infinite: true,
        centerMode: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    });
    $('.video-slider-wrapper .next').click(function () {
        $('.video-slider').slick('slickNext');
    });
    $('.video-slider-wrapper .prev').click(function () {
        $('.video-slider').slick('slickPrev');
    });

    $('.video-box').click(function () {
        var videoSrc = $(this).data('video-src');
        $('#videoModal iframe').attr('src', videoSrc + "?autoplay=1");
        setTimeout(function () {
            $('#videoModal').modal('show');
        },500);
    });

    $('#videoModal').on('hide.bs.modal', function (e) {
        $('#videoModal iframe').attr('src', "");
    })


    if($(window).width() < 767) {
        $('.product-list').slick({
            arrows: false,
            dots: true,
            fade: false,
            slide: '.product',
            autoplay: false,
            infinite: true,
            centerMode: false,
            slidesToShow: 3,
            slidesToScroll: 3
        });
    }

    $(".menu-bars").click(function () {
        $('.main-menu').slideToggle();
    });
});